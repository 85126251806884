<script setup>
import { storeToRefs } from "pinia";
import { watch, ref } from "vue";
import { useAuthStore, useMachinesStore } from "@/stores";
import { RouterLink } from "vue-router";
import router from "@/router";
const authStore = useAuthStore();
const { user: authUser } = storeToRefs(authStore);
const machineStore = useMachinesStore();
const { mymachines } = storeToRefs(machineStore);
const selectedID = ref("");
const descriptcionText = ref("");
const calcStock = ref("");
const uvlamp = ref("");
const fiveliterPrice = ref(0);
const nineteenliterPrice = ref(0);

function clicked(machine) {
  selectedID.value = machine.id;
  // console.log("Selected Machine ID:", selectedID.value);
  if (machine.stock > 500 && machine.stock < 2000) {
    calcStock.value = "\u26A0 Stok Miktarı Yakın Zamanda Bitebilir";
  } else if (machine.stock <= 500) {
    calcStock.value = "\u0021 Stok Miktarı Bitmek Üzere!";
  } else {
    calcStock.value = "\u2713 Stok Miktarı Yeterli";
  }

  if (machine.uvLampTime > 600 && 1000 > machine.uvLampTime) {
    uvlamp.value = "\u26A0 UV Lambası Süresi Yarım Ömrünü Doldurdu";
  } else if (machine.uvLampTime > 1000) {
    uvlamp.value = "\u0021 UV Lambası Süresi Bitmek Üzere!";
  } else {
    uvlamp.value = "\u2713 UV Lambası Süresi Yeterli";
  }
  descriptcionText.value = `${calcStock.value} - ${uvlamp.value}`;
  // console.log("Description:", descriptcionText.value);
}
function saveSettings(index) {
  // machineStore.setSetting(
  //   selectedID.value,
  //   fiveliterPrice.value,
  //   nineteenliterPrice.value
  // );
  switch (index) {
    case 1:
      // console.log("parametre kayıt");
      machineStore.setSetting(
        selectedID.value,
        fiveliterPrice.value,
        nineteenliterPrice.value,
        2,
        0
      );
      break;
    case 2:
      // console.log("devre dışı bırakma");
      machineStore.setSetting(selectedID.value, -1, -1, 1, 0);
      break;

    case 3:
      machineStore.setSetting(selectedID.value, -1, -1, 2, 0);
      break;
    case 4:
      // console.log("5lt satış");
      machineStore.setSetting(selectedID.value, -1, -1, 2, 2);
      break;
    case 5:
      // console.log("19lt satış");
      machineStore.setSetting(selectedID.value, -1, -1, 2, 1);
      break;

    default:
      break;
  }
}
watch(
  authUser,
  (newVal, oldVal) => {
    // Yeni değer mevcut olduğunda ve machineID özelliği tanımlı olduğunda
    if (newVal) {
      if (newVal.isAdmin) {
        router.push("/settings");
      }
    }
    if (newVal && newVal.machineID) {
      // console.log("Machine ID:", newVal.machineID);
      // machineID ile ilgili işlemleri burada yapabilirsiniz
      machineStore.getByID(newVal.machineID);
    }
  },
  { immediate: true }
);
defineExpose({ clicked, saveSettings, descriptcionText });
</script>

<template>
  <div class="home">
    <div class="scrollable-table">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Aktiflik Durumu</th>
            <th scope="col">Kısa Not</th>
            <th scope="col">ID</th>
            <!-- <th scope="col">Bölge Kodu</th>
            <th scope="col">Enlem</th>
            <th scope="col">Boylam</th> -->
            <th scope="col">Ciro</th>
            <th scope="col">Stok Miktarı(Lt)</th>
            <th scope="col">UV Lambası Süresi(Saat)</th>
            <th scope="col">5LT Satış Adet</th>
            <th scope="col">19LT Satış Adet</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(machine, index) in mymachines"
            :key="machine.id"
            @click="clicked(machine)"
          >
            <!-- <th scope="row">{{ machine.activity }}</th> -->
            <td>
              <span
                v-if="machine.activity === 'active'"
                style="color: yellowgreen"
                >Aktif</span
              >
              <span v-else style="color: brown">Deaktif</span>
            </td>
            <th scope="row">{{ machine.shortNote }}</th>
            <th scope="row">{{ machine.id }}</th>
            <!-- <td>{{ machine.regionCode }}</td>
            <td>{{ machine.latitude }}</td>
            <td>{{ machine.longitude }}</td> -->
            <td>{{ machine.totalRevenue }}</td>
            <td>{{ machine.stock }}</td>
            <td>{{ machine.uvLampTime }}</td>
            <td>{{ machine.fiveLiterSales }}</td>
            <td>{{ machine.nineteenLiterSales }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="description" v-show="!descriptcionText == ''">
      <h4>{{ descriptcionText }}</h4>
    </div>
    <div class="settings-input" v-show="!descriptcionText == ''">
      <h3>({{ selectedID }}) Makineye Ait Fiyat Ayarlarını Değiştir.</h3>
      <div class="input-texts">
        <h4>5 Litre Fiyat</h4>
        <h4>19 Litre Fiyat</h4>
      </div>
      <div class="inputs">
        <input v-model="fiveliterPrice" type="number" placeholder="" />
        <input v-model="nineteenliterPrice" type="number" placeholder="" />
      </div>
      <div class="button-list">
        <button @click="saveSettings(1)">Kaydet</button>
        <button @click="saveSettings(2)">Devre Dışı Bırak</button>
        <button @click="saveSettings(3)">Aktifleştir</button>
      </div>
      <div class="button-list">
        <button @click="saveSettings(4)">5LT Satış Yap</button>
        <button @click="saveSettings(5)">19LT Satış Yap</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.scrollable-table {
  width: 100%;
  table {
    @media (max-width: 768px) {
      width: 90%;
      max-height: 300px;
      display: block;
      overflow-x: auto;
      overflow-y: auto;
    }
    width: 50%;
  }
}
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .description {
    margin-top: 10px;
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    background-color: #4f8a8b;
    //border: 2px #467f80;
    border-radius: 5px;
    h4 {
      margin: 3px 3px;
      color: rgb(255, 255, 255);
    }
    @media (max-width: 768px) {
      width: 90%;
    }
  }
  .settings-input {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3 {
      margin-bottom: 10px;
    }
    .input-texts {
      display: flex;
      justify-content: space-around;
      width: 50%;
      h4 {
        margin: 3px 3px;
      }
    }
    .inputs {
      display: flex;
      justify-content: space-around;
      width: 50%;
      input {
        background-color: #3044634f;
        border-radius: 4px;
        width: 100px;
        height: 30px;
        margin: 3px 3px;
        padding: 5px;
      }
    }
    .button-list {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 60%;
    }
    button {
      cursor: pointer;
      margin-top: 10px;
      width: 100px;
      height: 50px;
      background-color: #7d8abc4d;
      color: rgb(255, 255, 255);
      border-radius: 5px;
      border: none;
    }
    button:hover {
      background-color: #7d8abc;
    }
  }
}
</style>
