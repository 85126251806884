import { defineStore } from "pinia";

import { fetchWrapper } from "@/router";

const baseUrl = `https://subank.info:8090/users`;
const array = ["a", "b"];
export const useMachinesStore = defineStore({
  id: "machines",
  state: () => ({
    mymachines: {},
    machines: {},
  }),
  actions: {
    async getByID(ids) {
      this.mymachines = { loading: true };
      const machineID = ["a"];
      const mymachines = await fetchWrapper.post(`${baseUrl}/mymachines`, ids);
      console.log(mymachines);
      this.mymachines = mymachines;
    },
    async getAll() {
      this.machines = { loading: true };
      try {
        const machines = await fetchWrapper.get(`${baseUrl}/machines`); // Veri çekme işlemi
        this.machines = machines; // Çekilen veriyi değişkene ata
      } catch (error) {
        console.error("Makineleri çekerken bir hata oluştu:", error);
        this.machines = { error };
      }
      // console.log("ssd")
      // console.log(this.machines);
    },
    async setSetting(
      id,
      fiveLiterPrice,
      nineteenLiterPrice,
      workStatus,
      except
    ) {
      // console.log(id);
      // console.log(fiveLiterPrice);
      // console.log(nineteenLiterPrice);
      // console.log(workStatus);
      // console.log(except);
      const settings = await fetchWrapper.post(`${baseUrl}/settings`, {
        id,
        fiveLiterPrice,
        nineteenLiterPrice,
        workStatus,
        except,
      });
      console.log(settings);
    },
  },
});
